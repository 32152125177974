 .custom-header {
    background-color: #5E2B7E;
    color: #fff;
    font-size: 22px;
  }

  .custom-header {
    font-size: 22px;
  }
  
  .user-name {
    font-size: 18px;
  }
  
  .small.text-muted {
    color: #999;
  }
  
  .new-badge {
    background-color: #007bff;
    color: #fff;
    font-weight: bold;
    padding: 2px 4px;
    border-radius: 2px;
  }
  
  .account-status {
    display: inline-block;
    padding: 4px 8px;
    font-weight: bold;
    border-radius: 4px;
  }
  
  .account-status.success {
    background-color: #28a745;
    color: #fff;
  }
  
  .account-status.warning {
    background-color: #ffc107;
    color: #212529;
  }
  
  .account-status.danger {
    background-color: #dc3545;
    color: #fff;
  }
  
  .action-button {
    margin-right: 5px;
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
  }
  
  .pagination ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  
  .pagination li {
    margin-right: 0.5rem;
  }
  
  .pagination button {
    border: none;
    background: none;
    padding: 0.25rem;
    cursor: pointer;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
  }
  
  .pagination button:hover {
    background-color: #f0f0f0;
  }
  
  .pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  .pagination .active button {
    background-color: #007bff;
    color: #fff;
  }
  
  .pagination .active button:hover {
    background-color: #007bff;
  }
  
  .pagination .page-link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #007bff;
    border: 1px solid #007bff;
    border-radius: 4px;
    padding: 0.25rem;
  }
  
  .pagination .page-link:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  .pagination .page-link:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination .page-link svg {
    fill: #007bff;
    margin: 0 0.25rem;
    height: 0.875rem;
    width: 0.875rem;
  }
  

  .responsive-image {
    width: 50px;
    height:50px;
    margin-bottom: 5px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
  